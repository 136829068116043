<template>
    <v-app>
        <HeaderContainer />

        <v-main style="background-color: #ddd;">
            <v-container fluid>
                <v-card
                    class="mx-auto mt-4 pb-6"
                    max-width="900"
                >
                    <v-card-title justify="center">Pedido: {{ getOrderId }}</v-card-title>
                    <v-card-subtitle v-if="pedido.user?.name" justify="start">
                    {{ pedido.user.name }} ({{ pedido.user.cpf }})
                    </v-card-subtitle>

                    <div style="border-bottom: solid 1px #ddd; margin: 0 10px 0 10px;"/>

                    <v-card-text justify="start">
                        <v-simple-table>
                            <template v-slot:default>
                                <tbody>
                                    <tr v-for="(item, index) in items" :key="index">
                                        <td style="text-align: left; width: 20px; border-bottom: solid 1px #ddd;">
                                            {{ item.quantidade }}x
                                        </td>
                                        <td style="text-align: left; border-bottom: solid 1px #ddd;">
                                            {{ item.nome }}
                                        </td>
                                        <td style="text-align: left; border-bottom: solid 1px #ddd;">
                                            R$ {{ formatPrice(+item.quantidade * (item.valor_promocional || item.valor_venda)) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>

                    <v-card-title justify="center">
                        Total: R$ {{ formatPrice(pedido.total_liquido) }}
                    </v-card-title>

                    <v-card-text v-if="!pedido.user?.cpf">
                        <FormInputMask
                            label="Informe o seu CPF"
                            placeholder="000.000.000-00"
                            :mask="['###.###.###-##']"
                            v-model="cpf"
                        />
                    </v-card-text>

                    <v-card-title justify="center">Pagamento:</v-card-title>

                    <v-card-actions>
                        <v-row>
                            <!-- <v-col>
                                <v-btn
                                    :disabled="btnCartao.disabled || pedido.status != 'pendente'"
                                    :loading="btnCartao.loading"
                                    @click="cardPayment"
                                    color="green"
                                    class="white--text"
                                    large
                                    block
                                >
                                    <b>Pagar com cartão</b>
                                </v-btn>
                            </v-col> -->
                            <v-col>
                                <v-btn
                                    :disabled="btnPix.disabled || pedido.status != 'pendente'"
                                    :loading="btnPix.loading"
                                    @click="pixPayment"
                                    color="green"
                                    class="white--text"
                                    large
                                    block
                                >
                                    <b>Pagar com Pix</b><br><br>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                    <v-card-text v-if="qrcode && pagamentoPendente" style="text-align: left;">
                        <span><b>Pagar com Pix é rápido e seguro! É só seguir estes passos:</b><br><br></span>
                        <div :style="`display: ${$vuetify.breakpoint.smAndUp ? 'flex' : 'block'};`">
                            <div class="qrcode-container">
                                <div class="gray-box">
                                    <div class="white-box">
                                        <QrCode v-model="qrcode"/>
                                    </div>
                                </div>
                            </div>
                            <div class="ml-4">
                                <br>
                                <div style="display: flex; justify-content: center;">
                                    <v-btn @click="copiarCodigo" color="blue" class="white--text mb-2" small>
                                        <v-icon small class="mr-2">mdi-content-copy</v-icon>
                                        COPIAR CÓDIGO
                                    </v-btn>
                                </div>
                                <br>
                                <ul style="list-style: none;">
                                    <li>
                                        <span>1. <b>Abra o aplicativo ou Internet Banking</b> para pagar</span>
                                    </li>
                                    <li>
                                        <span>2. Na opção Pix, escolha <b>'Ler QR Code'</b></span>
                                    </li>
                                    <li>
                                        <span>3. <b>Leia o QR Code</b> ou, se preferir, <b>copie o código para Pix Copia e Cola.</b></span>
                                    </li>
                                    <li>
                                        <span>4. Revise as informações e <b>confirme o pagamento.</b></span>
                                    </li>
                                </ul>
                                <br>
                                <span style="font-size: 12px;">Você tem até <b>{{ paymentDueDate }}</b> para pagar. Depois disso este pedido será cancelado automaticamente.</span>
                                <br>
                            </div>
                        </div>

                        <div v-if="pagamentoPendente" class="mt-4">
                            <div class="mt-6" style="display: flex; justify-content: center;">
                                <v-alert
                                    outlined
                                    width="300"
                                    color="orange"
                                    type="warning"
                                >Aguardando pagamento</v-alert>
                            </div>

                            <div style="display: flex; justify-content: space-around;">
                                <span v-if="tries > 0" style="width: 50%; padding: 10px;">
                                    Verificação automática de pagamento em {{ counter }} segundos...
                                </span>
                                <v-btn
                                    @click="onClickJaPaguei"
                                    color="#84109a"
                                    class="white--text"
                                    large
                                    :disabled="btnJaPaguei.disabled"
                                    :loading="btnJaPaguei.loading"
                                >
                                    Já Paguei
                                </v-btn>
                            </div>
                            <br>
                            <div style="text-align: center;">
                                <span><b>O seu pedido será enviado AUTOMATICAMENTE após a confirmação do pagamento!</b></span>
                            </div>
                        </div>
                    </v-card-text>

                    <v-card-text v-if="pedidoCancelado" class="mt-6" style="display: flex; justify-content: space-around; flex-direction: column; align-items: center;">
                        <div style="margin-top: -5px">
                        <v-alert
                            outlined
                            width="300"
                            color="red"
                            type="error"
                        ><b>Pedido Cancelado</b></v-alert>
                        </div>
                    </v-card-text>

                    <v-card-text v-if="pagamentoConfirmado" class="mt-6" style="display: flex; justify-content: space-around; flex-direction: column; align-items: center;">
                        <div style="margin-top: -5px">
                            <v-alert
                                outlined
                                width="300"
                                color="green"
                                type="success"
                            ><b>Pagamento Confirmado</b></v-alert>
                        </div>
                        
                        <br>
                        <div style="border: 1px solid #ddd; border-radius: 5px; width: 90%; padding: 10px; text-align: center;">
                            <v-card-subtitle style="text-align: center; font-size: 14px;">
                                <b>
                                    {{ seriais?.length ? 'Abaixo seguem as informações da sua compra:' : 'Os produtos digitais (códigos) da sua compra serão enviados automaticamente em alguns minutos.' }}
                                <br><br>
                                    {{ seriais?.length ? '' : 'Caso você não os receba em 24 horas, por favor entre em contato com o nosso suporte.' }}
                                </b>
                            </v-card-subtitle>
                            <div v-for="(produto, i) in getProdutosFisicos" :key="i" style="text-align: center; color: #000; margin-top: 15px; margin-bottom: 35px; border: 1px solid #000;">
                                <div style="font-size: 25px; margin: 10px 0 5px 0;">
                                    <b>{{ produto.nome }}</b>
                                </div>
                                <div style="padding: 5px">
                                    Entraremos em contato para combinar a entrega deste item.
                                </div>
                            </div>

                            <div v-for="(produto, i2) in getProdutosDigitais" :key="i2" style="text-align: center; color: #000; margin-top: 5px; margin-bottom: 36px;">
                                <div style="font-size: 22px; margin: 5px 0 5px 0;">
                                    <b>{{ produto.nome }}</b>
                                </div>
                                <table id="produtos-digitais">
                                    <tr>
                                        <td>
                                            Seu(s) Código(s) de Licença
                                        </td>
                                    </tr>
                                    <tr v-for="(serial, i3) in produto.seriais" :key="i3">
                                        <td style="font-size: 25px; padding: 8px 0 8px 0;">
                                            {{ serial.numero_serial }}
                                        </td>
                                    </tr>
                                </table>
                                <div v-if="produto.link_instrucoes">
                                    Se precisar de ajuda neste produto, <br>
                                    <a :href="produto.link_instrucoes" target="_blank">
                                        clique aqui para ver as instruções de uso
                                    </a>
                                    <br>
                                </div>
                            </div>
                        </div>
                    </v-card-text>

                    <v-card-actions v-if="pagamentoPendente">
                        <v-btn
                            @click="cancelarPedido"
                            color="#84109a"
                            text
                            :loading="btnCancelar.loading"
                        >
                            Cancelar Pedido
                        </v-btn>
                    </v-card-actions>
                    <v-card-actions v-else>
                        <v-btn
                            class="white--text"
                            color="primary"
                            text
                            @click="$router.push('/')"
                            >
                            Continuar Comprando</v-btn>
                    </v-card-actions>
                    </v-card>
                <!-- <MercadoPagoCheckout /> -->
            </v-container>
        </v-main>
        <FooterContainer />
    </v-app>
</template>

<script>
import QrCode from '@/components/QrCode';
import FooterContainer from './FooterContainer';
import HeaderContainer from './HeaderContainer';
import FormInputMask from '@/components/FormInputMask';
// import MercadoPagoCheckout from './MercadoPagoCheckout';

// eslint-disable-next-line
import { installMercadoPago, open } from '@/plugins/mercadoPago';
import { validateCpf } from '@/utils/validateCpf';

export default {
    name: 'CheckoutPage',

    components: {
        QrCode,
        FormInputMask,
        FooterContainer,
        HeaderContainer,
        // MercadoPagoCheckout,
    },

    data: () => ({
        btnCartao: {
            loading: false,
            disabled: false,
        },
        btnPix: {
            loading: false,
            disabled: false,
        },
        btnJaPaguei: {
            loading: false,
            disabled: false,
        },
        btnCancelar: {
            loading: false,
        },
        uui: null,
        pedido: {},
        seriais: [],
        qrcode: '',
        cpf: '',
        paymentDueDate: '',
        interval: null,
        counter: 30,
        tries: 6,
    }),

    mounted() {
        this.uui = this.$route.params.id;

        this.initCheckout();
    },

    beforeDestroy() {
        clearInterval(this.interval);
    },

    computed: {
        items() {
            const produtos = this.pedido?.produtos || [];
            const pacotes = this.pedido?.pacotes || [];
            return [...produtos, ...pacotes];
        },

        pagamentoPendente() {
            return this.pedido?.status === 'pendente';
        },

        pagamentoConfirmado() {
            return ['pago', 'entregue'].includes(this.pedido?.status);
        },

        pedidoCancelado() {
            return this.pedido?.status === 'cancelado';
        },

        getOrderId() {
            return this.pedido?.id || '';
        },

        getProdutosFisicos() {
            const produtosFisicos = this.pedido.produtos.filter(p => p.tipo === 'fisico');

            this.pedido.pacotes.forEach(pacote => {
                console.log(pacote.produtos.filter(p => p.tipo === 'fisico'))
                produtosFisicos.concat(pacote.produtos.filter(p => p.tipo === 'fisico'));
            });

            return produtosFisicos;
        },

        getProdutosDigitais() {
            const produtos = [];
            const seriais = this.pedido?.seriais || this.seriais || [];

            seriais.forEach(serial => {
                const produtoId = serial.produto.id;
                let produto = produtos.find(p => p.id === produtoId);

                if (!produto) {
                    produto = {
                        id: produtoId,
                        nome: serial.produto.nome,
                        link_instrucoes: serial.produto.link_instrucoes,
                        seriais: [],
                    };
                    produtos.push(produto);
                }

                produto.seriais.push(serial);
            });

            return produtos;
        },
    },

    methods: {
        initCheckout() {
            if (!this.uui) {
                return;
            }
            this.$http.get(`ecommerce/pedidos/${this.uui}`).then(resp => {
                if (resp.data.type === 'success') {
                    this.pedido = resp.data.data;

                    if (this.pedido.user?.cpf) {
                        this.cpf = this.pedido.user.cpf;
                    }

                    if (!this.pedido.pagamentos?.length) {
                        return;
                    }

                    this.setPaymentDueDate();

                    const payment = this.pedido.pagamentos.at(-1);

                    if (this.pedido.forma_pagamento === 'pix') {
                        // this.cpf = payment.request.customer.cpfCnpj;

                        this.qrcode = payment.qrcode;

                        this.seriais = this.pedido.seriais;

                        this.btnCartao.disabled = true;
                        this.btnPix.disabled = true;
                    }

                    if (this.pedido.status === 'pendente') {
                        this.startTimer();
                    }
                }

                if (resp.data.type === 'warning') {
                    this.$toast.error(resp.data.msg);
                    this.$router.push('/');
                }
            })
            .catch(e => this.$toast.error('Não foi possível concluir a operação ' + e));
        },

        checkPayment() {
            if (!this.pagamentoPendente) {
                return;
            }

            this.btnJaPaguei = {loading: true, disabled: true};
            this.$http.get(`ecommerce/pedidos/${this.uui}/check-payment`).then(resp => {
                const data = resp.data;

                if (data.type === 'success') {
                    this.pedido.status = data.data.status;
                    this.seriais = data.data?.seriais || [];

                    this.$forceUpdate();

                    if (this.pedido?.status === 'pago') {
                        clearInterval(this.interval);
                        return;
                    }

                    this.$toast.warning(`Pedido com pagamento ${this.pedido?.status}`);
                }

                if (data.type === 'warning') {
                    this.$toast.error(data.msg);
                }
            })
            .catch(e => this.$toast.error('Não foi possível concluir a operação ' + e))
            .finally(() => (this.btnJaPaguei = {loading: false, disabled: false}));
        },

        async cardPayment() {
            if (!validateCpf()) {
                this.$toast.error('O CPF informado é inválido, por favor verifique.');
                return;
            }

            await installMercadoPago();

            this.btnPix.disabled = true;
            this.btnCartao.loading = true;

            const param = {
                uuid: this.uui,
                type: 'card',
                cpf: this.cpf,
            };
            this.$http.post('ecommerce/pagamento', param).then(resp => {
                const data = resp.data.data;

                if (!data.preferenceId) {
                    alert('Não foi possível gerar a cobrança!')
                    return;
                }

                // TODO: ajustar chave
                // const publicKey = 'TEST-de8b2ac1-9ee9-450f-9082-c93e685ffe1c'; // test key
                const publicKey = 'APP_USR-c0174574-7eee-45ee-bda6-f1edf59785a5'; // production key
                open(publicKey, data.preferenceId);
            }).finally(() => {
                this.btnCartao.loading = false;
            });
        },

        copiarCodigo() {
           navigator.clipboard.writeText(this.qrcode).then(
                () => this.$toast.success('Código copiado!'),
                () => this.$toast.error('Falha ao copiar!')
            );
        },

        startTimer() {
           this.interval = setInterval(() => {
                if (this.counter <= 0) {
                    (this.tries <= 0) && clearInterval(this.interval);

                    this.counter = 30;
                    this.tries -= 1;

                    this.checkPayment();
                }

                this.counter -= 1;
            }, 1000);
        },

        onClickJaPaguei() {
            this.counter = 30;
            this.tries -= 1;

            this.checkPayment();
        },

        pixPayment() {
            const invalidCpf = !validateCpf(this.cpf);

            if (invalidCpf) {
                this.$toast.error('O CPF informado é inválido, por favor verifique.');
                return;
            }

            this.btnCartao.disabled = true;
            this.btnPix = {disabled: true, loading: true};

            const param = {
                uuid: this.uui,
                type: 'pix',
                cpf: this.cpf,
            };
            this.$http.post('ecommerce/pagamento', param).then(resp => {
                const data = resp.data.data;

                this.qrcode = data.pix.qrcode;

                this.setPaymentDueDate(data.pix.request.dueDate);

                this.btnCartao.disabled = true;
                this.btnPix = {disabled: true, loading: false};

                this.startTimer();
            }).catch(() => {
                this.btnCartao.disabled = false;
                this.btnPix = {disabled: false, loading: false};
            })
            .finally(() => {
                // this.btnCartao.loading = false;
            });
        },

        setPaymentDueDate(dueDate = '') {
            if (dueDate) {
                this.paymentDueDate = this.moment(dueDate).format('DD/MM/YYYY HH:mm');
                return;
            }

            if (!this.pedido.pagamentos?.length) {
                return;
            }

            const payment = this.pedido.pagamentos.at(-1);
            this.paymentDueDate = this.moment(payment.request.dueDate).format('DD/MM/YYYY HH:mm');
        },

        async cancelarPedido() {
            if (!confirm('Deseja realmente CANCELAR?')) {
                return;
            }

            this.btnCancelar.loading = true;

            this.$http.post(`ecommerce/pedido/${this.pedido.uuid}/cancelar`).then(resp => {
                if (resp.data.type === 'warning') {
                    this.$toast.error('Não foi possivel concluir a operação');
                    return;
                }

                this.initCheckout();
            })
            .finally(() => {
                this.btnCancelar.loading = false;
            });
        },
    },
}
</script>

<style scoped>
.v-card__subtitle {
    text-align: left;
    padding: 5px 0 5px 20px;
}

.gray-box {
    background-color: #ddd;
    border-radius: 5px;
    height: 230px;
    width: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.white-box {
    background-color: #fff;
    height: 200px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.qrcode-container {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    min-width: 50%;
}

#produtos-digitais {
    width: 100%;
    border-collapse: collapse;
}

#produtos-digitais td {
    border: 1px solid;
    border-radius: 5px;
    padding: 5px;
}
</style>