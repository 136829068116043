<template>
  <!-- eslint-disable-next-line  -->
  <v-dialog v-model="visible" persistent max-width="600px">
    <v-card class="elevation-12">
      <v-toolbar dark color="primary">
        <v-spacer />
        <b>Cadastro de nova conta</b>
        <v-spacer />
        <v-btn @click="$emit('close')" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-6">
        <v-form ref="form">
          <v-text-field
            label="Nome Completo"
            ref="nome"
            :rules="rules.nome"
            prepend-icon="person"
            v-model="form.name"
            autocomplete="username"
            @keypress.enter="$refs.cpf.focus()"
          />
          <!-- <v-text-field
            label="CPF (opcional)"
            ref="cpf"
            :rules="rules.cpf"
            prepend-icon="person"
            v-model="form.cpf"
            placeholder="000.000.000-00"
            v-mask="['###.###.###-##']"
            autocomplete="document"
            @keypress.enter="$refs.email.focus()"
          /> -->
          <v-text-field
            label="E-mail"
            ref="email"
            :rules="rules.email"
            prepend-icon="email"
            v-model="form.email"
            autocomplete="email"
            @keypress.enter="$refs.phone.focus()"
          />
          <!-- <v-text-field
            label="Telefone"
            ref="phone"
            :rules="rules.telefone"
            prepend-icon="phone"
            placeholder="+55 (99) 99999-9999"
            v-mask="['+## (##) ####-####', '+## (##) #####-####']"
            v-model="form.phone"
            @keypress.enter="$refs.password.focus()"
          /> -->
          <VuePhoneNumberInput
            v-model="form.phone"
            @update="phone = $event"
            @keypress.enter="$refs.password.focus()"
            ref="phone"
            :translations="{
              countrySelectorLabel: 'Código do país',
              countrySelectorError: 'Código do país',
              phoneNumberLabel: 'Telefone',
              example: ''
            }"
            no-example
            class="mt-2"
          />
          <v-text-field
            label="Senha (mínimo 8 caracteres, use letras e números)"
            ref="password"
            :rules="rules.password"
            v-model="form.password"
            prepend-icon="lock"
            @keypress.enter="$refs.passwordConfirm.focus()"
            autocomplete="current-password"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          />
          <v-text-field
            label="Digite a senha novamente"
            ref="passwordConfirm"
            :rules="rules.passwordConfirm"
            v-model="form.password_confirm"
            prepend-icon="lock"
            @keypress.enter="createAccount"
            autocomplete="current-password"
            :type="showPasswordConfirm ? 'text' : 'password'"
            @click:append="showPasswordConfirm = !showPasswordConfirm"
            :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
          />
        </v-form>
      </v-card-text>
      <v-card-actions class="mt-2">
        <v-btn
          @click="createAccount"
          block
          large
          color="primary"
          :loading="loading"
          class="white--text"
        >Criar nova conta</v-btn>
      </v-card-actions>
      <v-card-actions class="mt-4">
        <v-btn @click="$emit('close')" block text small>Já tenho uma conta</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
  name: 'NewAccountDialog',

  components: {
    VuePhoneNumberInput,
  },

  props: {
    visible: {
      type: Boolean,
      default: false
    },
  },

  data: () => ({
    form: {
      cpf: '',
      name: '',
      email: '',
      phone: '',
      password: '',
      password_confirm: '',
    },
    phone: '',
    rules: {
      nome: [
        v => !!v || 'Nome é obrigatório',
      ],
      telefone: [
        v => !!v || 'Telefone é obrigatório',
        //v => (v && v.length > 13) || 'Telefone precisa ter 11 digitos',
      ],
      cpf: [
        v => (v && v.length > 13) || 'CPF precisa ter 11 digitos',
      ],
      password: [
        v => !!v || 'Senha é obrigatório',
        v => (v && v.length >= 8) || 'Senha precisa ter no mínimo 8 digitos, use letras e números',
      ],
      passwordConfirm: [
        v => !!v || 'Confirmação de senha é obrigatório',
      ],
      email: [
        v => !!v || 'E-mail é obrigatório',
        v => /.+@.+\..+/.test(v) || 'E-mail inválido',
      ],
    },
    loading: false,
    showPassword: false,
    showPasswordConfirm: false,
  }),

  methods: {
    createAccount() {
      if (!this.validateForm()) {
        this.$toast.error('O formulário contém erros');
        return;
      }

      this.form.phone = this.phone.formatInternational;

      this.loading = true;
      this.$http.post('ecommerce/create-new-account', this.form).then(resp => {
        const data = resp.data;

        if (data.type === 'warning') {
          this.$toast.error(data.msg);
          return;
        }

        this.$toast.success(data.msg);
        this.$emit('newAccount', data.data);
      })
      .catch(e => (this.$toast.error('Não foi possivel concluir a operação. ' + e)))
      .finally(() => (this.loading = false))
    },

    validateForm() {
      return this.$refs.form.validate();
    },
  },
}
</script>

<style scoped>

</style>