<template>
    <v-menu offset-y>
        <slot name="principal" />
        <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on">
                <v-icon>mdi-account</v-icon>
                <span>{{ getUserName(10) }}</span>
            </v-btn>
        </template>
        <v-list>
            <v-list-item @click="$router.push('/ecommerce/minha-conta')">
                <v-list-item-title>
                    Minha Conta
                </v-list-item-title>
            </v-list-item>

            <v-list-item @click="$router.push('/ecommerce/meus-pedidos')">
                <v-list-item-title>
                    Meus Pedidos
                </v-list-item-title>
            </v-list-item>

            <v-list-item v-if="isAdmin" @click="$router.push('/dashboard')">
                <v-list-item-title>
                    Administrativo
                </v-list-item-title>
            </v-list-item>

            <v-list-item @click="logout">
                <v-list-item-title>
                    <v-icon class="mr-2" color="error">mdi-logout</v-icon>
                    <span style="color: red;">Sair</span>
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    name: 'UserOptions',

    props: ['value'],

    computed: {
        isAdmin() {
            return this.$store.state?.user?.tipo === 'admin';
        },
    },

    methods: {
        getUserName(limite=30) {
            const name = this.$store.state?.user?.name || '';
            let primeiroNome = name.split(' ')[0];
            return primeiroNome.substring(0,limite);
        },

        logout() {
            if (confirm('Deseja realmente SAIR?')) {
                this.$http.post('logout').then(() => {
                    this.$emit('logout');
                });
            }
        },
    },
}
</script>

<style scoped>

</style>