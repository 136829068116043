<template>
  <QrcodeVue
    v-if="value"
    :value="value"
    :size="size"
  />
</template>
<script>
import QrcodeVue from 'qrcode.vue'

export default {
  name: 'QrCode',

  components: {
    QrcodeVue,
  },

  props: {
    value: {
      type: String,
      default: '',
    },

    size: {
      type: Number,
      default: 180,
    },
  },
}
</script>