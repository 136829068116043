<template>
    <v-footer padless>
        <v-card flat tile width="100%" class="text-center" style="background-color: #84109a;">
            <v-card-text>
                <v-btn v-if="getRedeSocial('email')" class="mx-4" icon color="white" @click="sendEmail">
                    <v-icon size="24px">mdi-email</v-icon>
                </v-btn>
                <v-btn v-if="getRedeSocial('whatsapp')" class="mx-4" icon color="white" @click="sendWhatsapp">
                    <v-icon size="24px">mdi-whatsapp</v-icon>
                </v-btn>
                <v-btn v-if="getRedeSocial('youtube')" class="mx-4" icon color="white" @click="openLink('youtube')">
                    <v-icon size="24px">mdi-youtube</v-icon>
                </v-btn>
                <v-btn v-if="getRedeSocial('instagram')" class="mx-4" icon color="white" @click="openLink('instagram')">
                    <v-icon size="24px">mdi-instagram</v-icon>
                </v-btn>
                <v-btn v-if="getRedeSocial('facebook')" class="mx-4" icon color="white" @click="openLink('facebook')">
                    <v-icon size="24px">mdi-facebook</v-icon>
                </v-btn>
                <v-btn v-if="getRedeSocial('linkedin')" class="mx-4" icon color="white" @click="openLink('linkedin')">
                    <v-icon size="24px">mdi-linkedin</v-icon>
                </v-btn>
            </v-card-text>

            <v-divider></v-divider>

            <v-spacer />
            <span style="color: #f3ef0d; font-size: 13px; font-family: sans-serif;"><b>Se você deseja ser ou se já é uma
                    REVENDA
                    <a href="https://revenda.lojafacil.app" target="_blank" style="color: #f3880d;">
                        clique aqui</a></b>
            </span>
            <v-spacer />

            <v-card-text class="white--text">
                {{ new Date().getFullYear() }} — <strong>Loja Fácil</strong>
            </v-card-text>
        </v-card>
    </v-footer>
</template>

<script>
export default {
    name: 'FooterContainer',

    data: () => ({
    }),

    methods: {
        getRedeSocial(option) {
            return this.$store.state.config?.redes_sociais[option] || '';
        },

        openLink(option) {
            const url = this.getRedeSocial(option);
            window.open(url);
        },

        sendEmail() {
            const email = this.getRedeSocial('email');
            window.location.href = `mailto:${email}`;
        },

        sendWhatsapp() {
            const phone = '55' + this.getRedeSocial('whatsapp').replace(/\D/g, '');
            // window.open(`whatsapp://send?phone=${phone}`);
            window.open(`https://api.whatsapp.com/send?phone=+${phone}&text=Contato+pelo+site+Loja+Facil...`);
        },
    },
}
</script>
