<template>
  <!-- eslint-disable-next-line  -->
  <v-dialog v-model="visible" persistent max-width="600px">
    <v-card class="elevation-12">
      <v-toolbar dark color="primary">
        <v-spacer />
        Faça login para continuar
        <v-spacer />
        <v-btn @click="$emit('close')" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-6">
        <v-form>
          <v-text-field
            label="E-mail"
            ref="login"
            prepend-icon="person"
            type="text"
            v-model="email"
            autocomplete="username"
            @keypress.enter="$refs.password.focus()"
          />
          <v-text-field
            label="Senha"
            ref="password"
            v-model="password"
            prepend-icon="lock"
            @keypress.enter="login"
            autocomplete="current-password"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          />
        </v-form>
        <v-card-actions>
          <v-btn
            block
            @click="login"
            color="primary"
            :loading="loading"
            class="white--text"
          >Entrar</v-btn>
        </v-card-actions>
        <v-row class="mt-4">
          <v-col>
            <v-btn
              @click="createNewAccount"
              text
              small
              outlined
              color="primary"
            >Criar nova conta</v-btn>
          </v-col>
          <v-col>
            <v-btn
              @click="passwordReset"
              text
              small
            >Esqueci minha senha</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <NewAccountDialog
      :visible="newAccountDialogVisible"
      @newAccount="onNewAccount"
      @close="newAccountDialogVisible = false"
    />
  </v-dialog>
</template>

<script>
import NewAccountDialog from '@/views/account/NewAccountDialog';

export default {
  name: 'LoginDialog',

  components: {
    NewAccountDialog,
  },

  props: {
    visible: {
      type: Boolean,
      default: true
    },
  },

  data: () => ({
    email: '',
    password: '',
    loading: false,
    showPassword: false,
    newAccountDialogVisible: false,
  }),

  methods: {
    login() {
      if (!this.email) {
        this.notify('Informe o e-mail', 'warning');
        return;
      }
      if (!this.password) {
        this.notify('Informe a senha', 'warning');
        return;
      }

      this.loading = true;
      this.$http.post('login', {
        email: this.email,
        password: this.password
      }).then(async (resp) => {
        const user = resp.data;

        if (user.token) {
          this.makeLogin(user);
          return;
        }

        const msg = resp.data?.msg || 'Usuário ou senha inválido';
        this.$toast.error(msg);
      })
      .catch(() => (this.$toast.error('Não foi possivel realizar login')))
      .finally(() => (this.loading = false))
    },

    makeLogin(user) {
      sessionStorage.setItem('token', user.token);

      this.$http.defaults.headers.common = {'Authorization': `Bearer ${user.token}`};

      this.$store.commit('setUser', user);
      this.$store.commit('setCart', []);

      this.$emit('login');
    },

    passwordReset() {
      this.$router.push('/forgot-password');
    },

    createNewAccount() {
      this.newAccountDialogVisible = true;
    },

    onNewAccount(e) {
      this.email = e;
      this.newAccountDialogVisible = false;
    },
  },
}
</script>

<style scoped>

</style>