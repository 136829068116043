const id = "sdk_mercado_pago";

export function installMercadoPago() {
    return new Promise((resolve, reject) => {
        if (document.getElementById(id)) {
            resolve();
            return;
        }

        const s = document.createElement("script");
        s.type = "text/javascript";
        s.async = false;
        s.id = id;
        s.src = "https://sdk.mercadopago.com/js/v2";
        document.getElementsByTagName("head")[0].appendChild(s);
        s.onload = () => resolve();
        s.onerror = () => reject();
    });
}

export function open(publicKey, preferenceId) {
    // eslint-disable-next-line
    const mp = new MercadoPago(publicKey, {
        locale: 'pt-BR',
    });

    const checkout = mp.checkout({
        preference: {
            id: preferenceId,
        },
    });
    checkout.open();
}
